.company{
    /* border: 2px solid black; */
    padding: 5rem 0;
    background-color: #F6DA0A;
}
.company-container{
    /* border: 2px solid blue; */
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    gap: 5rem;
}
.company-textbox1{
    /* flex: 1; */
    flex: 1 1 90rem;
    /* max-width: 90rem; */
    height: 80rem;
    /* border: 2px solid orange;     */
    padding: 5rem;
    /* margin-left: 4rem; */
}
.company-textbox1 h1{
    font-size: 5rem;
    /* text-align: center; */
    margin-bottom: 3rem;
}
.company-textbox1 h2 {
    margin-bottom: 3rem;

}
.company-textbox1 p{
    font-size: 2rem;
    line-height: 4rem;
}

.company-textbox2{
    flex: 1 1 40rem;
    /* margin-right: 0; */
    /* border: 2px solid green; */
    /* max-width: 60rem; */
    height: 80rem;
    padding: 5rem;
    background-color: white;
    border-radius: 2rem;
}
.company-textbox2 h1{
    font-size: 5rem;
    margin-bottom: 3rem;
}
.company-textbox2 ul {
    list-style: none;
    font-size: 2rem;
    line-height: 5rem;
}
.company-btn{
    
        /* border: 2px solid black; */
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.5rem;
        padding: 2rem;
        background-color:black;
        font-weight: bold;         
        margin-top: 5rem;
        border: none;       
        cursor: pointer;
    
}
.company-btn:hover {
    background-color: white;
    
    transition: 0.5s;
}
.company-btn:hover >a {
    color: black;   
    transition: 0.5s;
}
.company-btn a{
    text-decoration: none;
    color: white;
    
}

/* Media Queries */

@media(max-width:1400px){
    
        
        .company-textbox2 ul {
            list-style: none;
            font-size: 1.8rem;
            line-height: 5rem;
        }
}

@media(max-width:1300px){
    
        
    .company-textbox1 p {
        list-style: none;
        font-size: 1.8rem;
        line-height: 4rem;
    }
}



@media(max-width:1050px){
    .company-textbox1 p {
        font-size: 1.5rem;
        line-height: 3rem;
    }
    .company-textbox2 ul {
        list-style: none;
        font-size: 1.5rem;
        line-height: 4rem;
    }
}

@media(max-width:915px){
    

    .company-container{
     
        gap: 0rem;
    }

    .company-textbox1{
        font-size: 1.5rem;
        line-height: 2.8rem;
       max-width: 40rem;
        height: 100rem;
       
    }
    .company-textbox2{
       max-width: 35rem;
        height: 100rem;
       
    }
}

@media(max-width:800px){
    
/* 
    .company-container{
     
        gap: 0rem;
    } */

    .company-textbox1{
        /* font-size: 1.5rem; */
        line-height: 1.8rem;        
       max-width: 40rem;
        height: 100rem;
       
    }
    .company-textbox1 h2{
        font-size: 2rem;
    }
    .company-textbox2{
       max-width: 35rem;
        height: 100rem;
       
    }
}
@media(max-width:600px){
    .company{
        width: 100%;
    }
    .company-container{
     
        flex-wrap: wrap;
        gap: 5rem;
        
    }
    .company-textbox1{
        /* font-size: 1.5rem; */
        line-height: 2.8rem;
       max-width: 100%;
        /* height: 100rem; */
        padding: 0 10rem;
        height: 100%;;
       
    }
    .company-textbox1 h1{
        font-size: 4rem;
        
    }
    .company-textbox2{
        padding: 0 10rem;
       max-width: 100%;
        /* height: 100rem; */
       
    }
}

@media(max-width:450px){
    .company-textbox1{
        padding: 0rem;             
    }
    .company-textbox1 h1{
        text-align: center;
        font-size: 5rem;
    }
    .company-textbox2{
     padding: 0rem;
       max-width: 100%;
        height: 50rem;
       
    }
    .company-textbox2 h1{
        text-align: center;
        /* font-size: 5rem; */
    }
}