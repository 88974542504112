*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;

  
}
html{
  font-size: 62.5%;
}
.container{
  max-width: 1400px;
  width: 90%;
  margin: auto;
}
