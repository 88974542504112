.projects{
    /* border: 2px solid red; */
    padding: 10rem 0;
    background-color: #F6DA0A;
}

.project-Heading h1{
font-size: 5rem;
text-align: center;
margin-bottom: 4rem;
}
.project-Heading p {
    text-align: center;
    font-size: 2rem;
    line-height: 2;
    margin-bottom: 2rem;
}
.projects-container{
    /* border: 2px solid blue; */
}
.project-items{
    /* border: 2px solid green; */
    /* display: grid;
    grid-template-rows: repeat(2,35rem);
    grid-template-columns: repeat(3, 1fr); */
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;    
    padding: 2rem;
}
.projects-text{
   
    width: 35rem;
    height: 35rem;
    max-width: 35rem;
   
    border-radius: 2rem;
    background-color: white;
    
    
}


.projects-text h1{
    
    font-size: 3rem;
    font-weight: lighter;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 3rem;
}
.projects-text p {
    font-size: 1.5rem;
    line-height: 3rem;
    text-align: center;
    padding: 0 2rem;
    
}
.projects-text:nth-child(5){
    background-color: black;
    color: white;
}

/* .text-color{
    color: red;
} */

.projects-image{
    
    width: 35rem;
    height: 35rem;
    border-radius: 2rem;
}
.projects-image img {
    border-radius: 2rem;
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: auto;
    object-fit: cover;
    object-position: center;
    
}
/* .gap{
    margin: 2rem;
} */